<template>
  <v-container>
    <h3 class="headline logo_text">{{ title }}</h3>
    <h4
      v-if="desc && desc.length > 0"
      class="subheading font-weight-light font-italic logo_text"
    >
      <td v-html="desc"></td>
    </h4>
    <h4 v-if="link && link.title">
      <v-btn :href="link.url" target="_blank">
        <i class="fa fa-link" /> &nbsp;
        {{ link.title }}
      </v-btn>
    </h4>
    <div id="mapid" />
    <div id="controlid" />
    <br />
    <v-card v-if="showTable">
      <v-card-title>
        <h3 class="headline logo_text">
          <img v-if="pin && pin.length > 0" :src="pin" height="42" />
          <i v-else class="fa fa-map-marker logo_icon" />

          &nbsp;Markers
        </h3>
        <v-spacer />
        <v-text-field
          v-model="searchQuery"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :header-props="{ 'sort-icon': 'fa-arrow-up' }"
        :headers="columns"
        :items="markers"
        :items-per-page="itemsPerPage"
        :search="searchQuery"
      >
        <template v-slot:item.name="{ item }">
          <a
            style="cursor:pointer"
            title="Select"
            @click.prevent="select(item)"
          >
            <i class="fa fa-crosshairs crosshairs" />
          </a>
          {{ item.name }}
        </template>
        <template v-slot:item.icon="{ item }">
          <img
            v-if="item.icon && item.icon.length > 0"
            :src="item.icon"
            class="iconpicture"
          />
          <i v-else class="fa fa-picture-o fa-4x" />
        </template>
        <template v-slot:no-data>
          You have no markers yet!
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { SliderMap } from "slider-map";
import { MarkerType, ShowIcons } from "@/store/mapService";

export default {
  name: "Mapviewer",
  data() {
    return {
      showTable: true,
      itemsPerPage: 5,
      searchQuery: "",
      markers: [],
      title: "",
      map: null,
      pin: "",
      desc: "",
      link: {
        title: "",
        url: ""
      },
      showIcons: 1,
      markerType: 0
    };
  },
  computed: {
    hasData() {
      return this.markers.some(marker => {
        if (
          marker.originalData?.range?.end ||
          marker.originalData?.range?.start
        ) {
          return true;
        } else if (marker.originalData?.value) {
          return true;
        }

        return false;
      });
    },
    columns() {
      if (this.showIcons == ShowIcons.show) {
        if (!this.hasData) {
          return [
            { text: "Picture", value: "icon" },
            { text: "Name/Description", value: "name" },
            { text: "Location", value: "displayLocation" }
          ];
        }
        return this.markerType == MarkerType.date
          ? [
              { text: "Picture", value: "icon" },
              { text: "Name/Description", value: "name" },
              { text: "Date/Range", value: "displayData" },
              { text: "Location", value: "displayLocation" }
            ]
          : [
              { text: "Picture", value: "icon" },
              { text: "Name/Description", value: "name" },
              { text: "Value/Range", value: "displayData" },
              { text: "Location", value: "displayLocation" }
            ];
      } else {
        if (!this.hasData) {
          return [
            { text: "Name/Description", value: "name" },
            { text: "Location", value: "displayLocation" }
          ];
        }
        return this.markerType == MarkerType.date
          ? [
              { text: "Name/Description", value: "name" },
              { text: "Date/Range", value: "displayData" },
              { text: "Location", value: "displayLocation" }
            ]
          : [
              { text: "Name/Description", value: "name" },
              { text: "Value/Range", value: "displayData" },
              { text: "Location", value: "displayLocation" }
            ];
      }
    }
  },
  mounted() {
    if (this.$route.query.table) {
      this.showTable = this.$route.query.table.toLowerCase() === "true";
    }
    if (this.$route.query.itemsPerPage) {
      this.itemsPerPage = parseInt(this.$route.query.itemsPerPage) || 5;
    }
    this.loadMap();
  },
  methods: {
    select(marker) {
      this.map.select(marker);
    },
    loadMap() {
      this.map = new SliderMap(
        this.$route.params["mapId"],
        "mapid",
        "controlid"
      );
      this.map.addListener("update", markers => (this.markers = markers));
      this.map.addListener("metaData", metaData => {
        this.title = metaData.title;
        this.pin = metaData.pin;
        this.desc = metaData.description;
        this.link = metaData.link;
        this.showIcons = metaData.showIcons;
        this.markerType = metaData.markerType;
      });
      this.map.create(true);
    }
  }
};
</script>

<style scoped>
.crosshairs {
  color: #e63f5b;
}

.iconpicture {
  display: grid;
  margin: 5px 5px 5px 5px;
  border: solid;
  width: 50%;
  height: 100%;
  grid-template-columns: 50px;
  grid-template-rows: 50px;
}

/deep/ th i {
  margin-left: 7px;
}

/deep/ th.active {
  color: #e63f5b !important;
}

/deep/ th.active i {
  color: #e63f5b !important;
}
</style>
